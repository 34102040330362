import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "page-body" }
const _hoisted_2 = { class: "container-xl" }
const _hoisted_3 = { class: "row row-deck row-cards" }
const _hoisted_4 = { class: "col-sm-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = { style: {"flex":"1"} }
const _hoisted_8 = { class: "table card-table table-vcenter" }
const _hoisted_9 = { key: 0 }

import Timer from "@/components/home/Timer.vue";
import {ref} from "vue";
import ModalActionPlan from "@/components/home/ModalActionPlan.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Home',
  setup(__props) {


const data = [
  {
    "machine": "5-8",
    "eff_shift": "61%",
    "pro_name": "PRO Name",
    "part_name": "Part Name",
    "efficiency": "32%",
    "action": 1,
    "spv": 'Saiful',
    "status": 'Open'
  },
  {
    "machine": "7-1",
    "eff_shift": "66%",
    "pro_name": "PRO Name",
    "part_name": "Part Name",
    "efficiency": "47%",
    "action": 1,
    "spv": 'Saiful',
    "status": 'On Progress'
  },
  {
    "machine": "2-5",
    "eff_shift": "55%",
    "pro_name": "PRO Name",
    "part_name": "Part Name",
    "efficiency": "37%",
    "action": 1,
    "spv": 'Saiful',
    "status": 'On Progress'
  },
  {
    "machine": "9-7",
    "eff_shift": "41%",
    "pro_name": "PRO Name",
    "part_name": "Part Name",
    "efficiency": "67%",
    "action": 1,
    "spv": 'Saiful',
    "status": 'Close'
  },
  {
    "machine": "4-6",
    "eff_shift": "45%",
    "pro_name": "PRO Name",
    "part_name": "Part Name",
    "efficiency": "32%",
    "action": 1,
    "spv": 'Saiful',
    "status": 'Open'
  },
  {
    "machine": "6-9",
    "eff_shift": "48%",
    "pro_name": "PRO Name",
    "part_name": "Part Name",
    "efficiency": "44%",
    "action": null,
    "spv": 'Saiful',
    "status": 'Open'
  },
  {
    "machine": "3-4",
    "eff_shift": "64%",
    "pro_name": "PRO Name",
    "part_name": "Part Name",
    "efficiency": "69%",
    "action": null,
    "spv": 'Saiful',
    "status": 'Open'
  },
  {
    "machine": "8-2",
    "eff_shift": "38%",
    "pro_name": "PRO Name",
    "part_name": "Part Name",
    "efficiency": "50%",
    "action": null,
    "spv": 'Saiful',
    "status": 'Open'
  },
  {
    "machine": "1-3",
    "eff_shift": "69%",
    "pro_name": "PRO Name",
    "part_name": "Part Name",
    "efficiency": "59%",
    "action": null,
    "spv": 'Saiful',
    "status": 'Open'
  }
]
const showModal = ref(false)


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[5] || (_cache[5] = _createStaticVNode("<div class=\"page-header d-print-none\" data-v-9c2e251a><div class=\"container-xl\" data-v-9c2e251a><div class=\"row g-2 align-items-center\" data-v-9c2e251a><div class=\"col\" data-v-9c2e251a><div class=\"page-pretitle\" data-v-9c2e251a> Overview </div><h2 class=\"page-title\" data-v-9c2e251a> Dashboard </h2></div></div></div></div>", 1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(Timer)
                ]),
                _cache[2] || (_cache[2] = _createStaticVNode("<div style=\"flex:1;\" class=\"text-center\" data-v-9c2e251a><h1 class=\"mb-1\" data-v-9c2e251a>Shift Efficiency</h1><p data-v-9c2e251a>Foreman: Bambang</p></div><div style=\"flex:1;\" data-v-9c2e251a><div class=\"d-flex flex-column ps-5\" data-v-9c2e251a><div class=\"row\" data-v-9c2e251a><div class=\"col-sm-6 d-flex align-items-center mb-1\" data-v-9c2e251a><div class=\"legend legend-open\" data-v-9c2e251a></div> Open </div><div class=\"col-sm-6 d-flex align-items-center mb-1\" data-v-9c2e251a><div class=\"legend legend-close\" data-v-9c2e251a></div> Close </div></div><div class=\"row\" data-v-9c2e251a><div class=\"col-sm-6 d-flex align-items-center mb-1\" data-v-9c2e251a><div class=\"legend legend-waiting\" data-v-9c2e251a></div> Waiting </div><div class=\"col-sm-6 d-flex align-items-center mb-1\" data-v-9c2e251a><div class=\"legend legend-rejected\" data-v-9c2e251a></div> Rejected </div></div><div class=\"row\" data-v-9c2e251a><div class=\"col-sm-6 d-flex align-items-center mb-1\" style=\"white-space:nowrap;overflow:hidden;\" data-v-9c2e251a><div class=\"legend legend-on-progress\" data-v-9c2e251a></div> On Progress </div><div class=\"col-sm-6 d-flex align-items-center mb-1\" data-v-9c2e251a><div class=\"legend legend-deleted\" data-v-9c2e251a></div> Deleted </div></div></div></div>", 2))
              ]),
              _createElementVNode("table", _hoisted_8, [
                _cache[4] || (_cache[4] = _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", null, "No"),
                    _createElementVNode("th", null, "Machine"),
                    _createElementVNode("th", null, "Eff Shift"),
                    _createElementVNode("th", null, "PRO Name"),
                    _createElementVNode("th", null, "Part Name"),
                    _createElementVNode("th", null, "Efficiency"),
                    _createElementVNode("th", null, "Action"),
                    _createElementVNode("th", null, "SPV"),
                    _createElementVNode("th", null, "Status")
                  ])
                ], -1)),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.sort((a, b) => +a.eff_shift.localeCompare(b.eff_shift)), (v, k) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: v.id
                    }, [
                      _createElementVNode("td", null, _toDisplayString(k + 1), 1),
                      _createElementVNode("td", null, _toDisplayString(v.machine), 1),
                      _createElementVNode("td", null, _toDisplayString(v.eff_shift), 1),
                      _createElementVNode("td", null, _toDisplayString(v.pro_name), 1),
                      _createElementVNode("td", null, _toDisplayString(v.part_name), 1),
                      _createElementVNode("td", null, _toDisplayString(v.efficiency), 1),
                      _createElementVNode("td", null, [
                        (k < 5)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                              _cache[3] || (_cache[3] = _createElementVNode("button", { class: "btn btn-primary btn-sm me-2" }, " Detail Downtime ", -1)),
                              _createElementVNode("button", {
                                class: "btn btn-success btn-sm",
                                onClick: _cache[0] || (_cache[0] = ($event: any) => (showModal.value = true))
                              }, " Create AP ")
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("td", null, _toDisplayString(k < 5 ? v.spv : ''), 1),
                      _createElementVNode("td", null, [
                        (k < 5)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: _normalizeClass(`status legend-${v.status?.toLowerCase().replaceAll(' ', '-')}`)
                            }, _toDisplayString(v.status), 3))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  }), 128))
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(ModalActionPlan, {
      show: showModal.value,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (showModal.value = false))
    }, null, 8, ["show"])
  ], 64))
}
}

})