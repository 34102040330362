import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  class: "modal-dialog modal-xl modal-dialog-centered",
  role: "document"
}
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-header p-2" }
const _hoisted_4 = { style: {"flex":"1"} }
const _hoisted_5 = { style: {"flex":"1","text-align":"end"} }
const _hoisted_6 = { class: "modal-body p-0" }
const _hoisted_7 = { class: "table card-table" }
const _hoisted_8 = { class: "d-flex mb-1" }
const _hoisted_9 = ["onUpdate:modelValue"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "d-flex mb-1" }
const _hoisted_13 = ["onUpdate:modelValue"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "modal-footer" }

import Timer from "@/components/home/Timer.vue";
import {ref} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalActionPlan',
  props: {
  show: Boolean,
},
  emits: ['close', 'set-progress'],
  setup(__props, { emit: __emit }) {


const props = __props
const emit = __emit

const factors = ['Dies', 'Machine', 'Parameter Setting', 'Man Power', 'Metode']
const spvs = ['Budi', 'Santoso', 'Ahmad', 'Heru']

const problem = ref('')
const correctiveAction = ref('')
const factor = ref('Dies')
const analisa = ref([''])
const preventiveAction = ref([''])
const spv = ref('')

const addAnalisa = index => {
  let arr = analisa.value
  arr.splice(index + 1, 0, "")
  analisa.value = arr
}
const deleteAnalisa = index => {
  let arr = analisa.value
  if (arr.length === 1) {
    return
  }
  arr.splice(index, 1)
  analisa.value = arr
}

const addPreventiveAction = index => {
  let arr = preventiveAction.value
  arr.splice(index + 1, 0, "")
  preventiveAction.value = arr
}
const deletePreventiveAction = index => {
  let arr = preventiveAction.value
  if (arr.length === 1) {
    return
  }
  arr.splice(index, 1)
  preventiveAction.value = arr
}

const reset = () => {
  problem.value = ''
  correctiveAction.value = ''
  factor.value = 'Dies'
  analisa.value = ['']
  preventiveAction.value = ['']
  spv.value = ''
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal modal-blur fade show",
    id: "modal-large",
    tabindex: "-1",
    style: _normalizeStyle({display: __props.show ? 'block' : 'none'}),
    "aria-modal": "true",
    role: "dialog"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(Timer)
          ]),
          _cache[7] || (_cache[7] = _createElementVNode("div", { style: {"flex":"1","text-align":"center"} }, [
            _createElementVNode("h1", {
              class: "modal-title mb-0",
              style: {"font-size":"1.5rem"}
            }, "Action Plan"),
            _createElementVNode("p", null, "Foreman: Heru Budi")
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('close'))),
              class: "btn-close",
              "aria-label": "Close"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("table", _hoisted_7, [
            _cache[16] || (_cache[16] = _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "Problem"),
                _createElementVNode("th", null, "Corrective Action"),
                _createElementVNode("th", null, "Factor"),
                _createElementVNode("th", null, "Analisa"),
                _createElementVNode("th", null, "Preventive Action"),
                _createElementVNode("th", null, "SPV")
              ])
            ], -1)),
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", null, [
                  _withDirectives(_createElementVNode("textarea", {
                    class: "form-control form-control-sm",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((problem).value = $event)),
                    rows: "5"
                  }, null, 512), [
                    [_vModelText, problem.value]
                  ])
                ]),
                _createElementVNode("td", null, [
                  _withDirectives(_createElementVNode("textarea", {
                    class: "form-control form-control-sm",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((correctiveAction).value = $event)),
                    rows: "5"
                  }, null, 512), [
                    [_vModelText, correctiveAction.value]
                  ])
                ]),
                _createElementVNode("td", null, [
                  _withDirectives(_createElementVNode("select", {
                    class: "form-control form-control-sm",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((factor).value = $event))
                  }, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(factors, (factor) => {
                      return _createElementVNode("option", null, _toDisplayString(factor), 1)
                    }), 64))
                  ], 512), [
                    [_vModelSelect, factor.value]
                  ])
                ]),
                _createElementVNode("td", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(analisa.value, (_, k) => {
                    return (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        class: "form-control form-control-sm",
                        "onUpdate:modelValue": ($event: any) => ((analisa.value[k]) = $event)
                      }, null, 8, _hoisted_9), [
                        [_vModelText, analisa.value[k]]
                      ]),
                      _cache[10] || (_cache[10] = _createElementVNode("div", { style: {"width":"6px"} }, null, -1)),
                      (_openBlock(), _createElementBlock("svg", {
                        onClick: ($event: any) => (addAnalisa(k)),
                        style: {"cursor":"pointer"},
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "24",
                        height: "24",
                        viewBox: "0 0 24 24",
                        fill: "#30B344",
                        class: "icon icon-tabler icons-tabler-filled icon-tabler-circle-plus"
                      }, _cache[8] || (_cache[8] = [
                        _createElementVNode("path", {
                          stroke: "none",
                          d: "M0 0h24v24H0z",
                          fill: "none"
                        }, null, -1),
                        _createElementVNode("path", { d: "M4.929 4.929a10 10 0 1 1 14.141 14.141a10 10 0 0 1 -14.14 -14.14zm8.071 4.071a1 1 0 1 0 -2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0 -2h-2v-2z" }, null, -1)
                      ]), 8, _hoisted_10)),
                      _cache[11] || (_cache[11] = _createElementVNode("div", { style: {"width":"4px"} }, null, -1)),
                      (_openBlock(), _createElementBlock("svg", {
                        onClick: ($event: any) => (deleteAnalisa(k)),
                        style: {"cursor":"pointer"},
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "24",
                        height: "24",
                        viewBox: "0 0 24 24",
                        fill: "#D63938",
                        class: "icon icon-tabler icons-tabler-filled icon-tabler-circle-x"
                      }, _cache[9] || (_cache[9] = [
                        _createElementVNode("path", {
                          stroke: "none",
                          d: "M0 0h24v24H0z",
                          fill: "none"
                        }, null, -1),
                        _createElementVNode("path", { d: "M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" }, null, -1)
                      ]), 8, _hoisted_11))
                    ]))
                  }), 256))
                ]),
                _createElementVNode("td", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(preventiveAction.value, (_, k) => {
                    return (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        class: "form-control form-control-sm",
                        "onUpdate:modelValue": ($event: any) => ((preventiveAction.value[k]) = $event)
                      }, null, 8, _hoisted_13), [
                        [_vModelText, preventiveAction.value[k]]
                      ]),
                      _cache[14] || (_cache[14] = _createElementVNode("div", { style: {"width":"6px"} }, null, -1)),
                      (_openBlock(), _createElementBlock("svg", {
                        onClick: ($event: any) => (addPreventiveAction(k)),
                        style: {"cursor":"pointer"},
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "24",
                        height: "24",
                        viewBox: "0 0 24 24",
                        fill: "#30B344",
                        class: "icon icon-tabler icons-tabler-filled icon-tabler-circle-plus"
                      }, _cache[12] || (_cache[12] = [
                        _createElementVNode("path", {
                          stroke: "none",
                          d: "M0 0h24v24H0z",
                          fill: "none"
                        }, null, -1),
                        _createElementVNode("path", { d: "M4.929 4.929a10 10 0 1 1 14.141 14.141a10 10 0 0 1 -14.14 -14.14zm8.071 4.071a1 1 0 1 0 -2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0 -2h-2v-2z" }, null, -1)
                      ]), 8, _hoisted_14)),
                      _cache[15] || (_cache[15] = _createElementVNode("div", { style: {"width":"4px"} }, null, -1)),
                      (_openBlock(), _createElementBlock("svg", {
                        onClick: ($event: any) => (deletePreventiveAction(k)),
                        style: {"cursor":"pointer"},
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "24",
                        height: "24",
                        viewBox: "0 0 24 24",
                        fill: "#D63938",
                        class: "icon icon-tabler icons-tabler-filled icon-tabler-circle-x"
                      }, _cache[13] || (_cache[13] = [
                        _createElementVNode("path", {
                          stroke: "none",
                          d: "M0 0h24v24H0z",
                          fill: "none"
                        }, null, -1),
                        _createElementVNode("path", { d: "M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" }, null, -1)
                      ]), 8, _hoisted_15))
                    ]))
                  }), 256))
                ]),
                _createElementVNode("td", null, [
                  _withDirectives(_createElementVNode("select", {
                    class: "form-control form-control-sm",
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((spv).value = $event))
                  }, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(spvs, (v) => {
                      return _createElementVNode("option", null, _toDisplayString(v), 1)
                    }), 64))
                  ], 512), [
                    [_vModelSelect, spv.value]
                  ])
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("button", {
            type: "button",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (emit('close'))),
            class: "btn btn-primary"
          }, "Cancel"),
          _createElementVNode("button", {
            type: "button",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (emit('close'))),
            class: "btn btn-success"
          }, "Submit"),
          _createElementVNode("button", {
            type: "button",
            onClick: reset,
            class: "btn"
          }, "Reset")
        ])
      ])
    ])
  ], 4))
}
}

})