import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import Login from "@/views/Login.vue";
import Home from "@/views/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      unprotected: true,
    }
  },
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/oee-monitoring',
    name: 'oee-monitoring',
    component: () => import(/* webpackChunkName: "about" */ '@/views/OeeMonitoring.vue'),
  },
  {
    path: '/dashboard-lini',
    name: 'dashboard-lini',
    component: () => import(/* webpackChunkName: "about" */ '@/views/DashboardLine.vue'),
  },
  {
    path: '/action-plant',
    name: 'action-plant',
    component: () => import(/* webpackChunkName: "about" */ '@/views/SpvActionPlant.vue'),
  },
  {
    path: '/downtime-control',
    name: 'downtime-control',
    component: () => import(/* webpackChunkName: "about" */ '@/views/DowntimeControl.vue'),
  },
  {
    path: '/action-plan-monitoring',
    name: 'action-plan-monitoring',
    component: () => import(/* webpackChunkName: "about" */ '@/views/ActionPlanMonitoring.vue'),
  },
  {
    path: '/production-status',
    name: 'production-status',
    component: () => import(/* webpackChunkName: "about" */ '@/views/ProductionStatus.vue'),
  },
  {
    path: '/quality-performance',
    name: 'quality-performance',
    component: () => import(/* webpackChunkName: "about" */ '@/views/QualityPerformance.vue'),
  },
  {
    path: '/melting-report',
    name: 'melting-report',
    component: () => import(/* webpackChunkName: "about" */ '@/views/MeltingReport.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.unprotected) {
    next()
  }
  else {
    const token = localStorage.getItem('token');
    if (token) {
      next();
    } else {
      next('/login');
    }
  }
})

export default router
