import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withKeys as _withKeys, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic } from "vue"
import _imports_0 from '@/assets/logo.png'


const _hoisted_1 = { class: "container container-tight py-4" }
const _hoisted_2 = { class: "card card-md" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = {
  action: "./",
  method: "get",
  autocomplete: "off",
  novalidate: ""
}
const _hoisted_5 = { class: "mb-3" }
const _hoisted_6 = {
  key: 0,
  class: "invalid-feedback"
}
const _hoisted_7 = { class: "mb-2" }
const _hoisted_8 = { class: "input-group input-group-flat" }
const _hoisted_9 = ["type"]
const _hoisted_10 = {
  href: "javascript:;",
  class: "link-secondary",
  title: "Show password",
  "data-bs-toggle": "tooltip"
}
const _hoisted_11 = {
  key: 0,
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  stroke: "currentColor",
  "stroke-width": "2",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  class: "icon icon-tabler icons-tabler-outline icon-tabler-eye-off"
}
const _hoisted_12 = {
  key: 1,
  xmlns: "http://www.w3.org/2000/svg",
  class: "icon",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  "stroke-width": "2",
  stroke: "currentColor",
  fill: "none",
  "stroke-linecap": "round",
  "stroke-linejoin": "round"
}
const _hoisted_13 = {
  key: 0,
  class: "invalid-feedback"
}

import {ref, watch} from "vue";
import router from "@/router";


export default /*@__PURE__*/_defineComponent({
  __name: 'Login',
  setup(__props) {


const username = ref("")
const password = ref("")
const usernameError = ref("")
const passwordError = ref("")
const showPwd = ref(false)

const login = () => {
  if (username.value.length < 2) {
    usernameError.value = 'NIK / Username tidak ditemukan'
    return
  }
  if (password.value != '111111') {
    passwordError.value = 'Password salah'
    return
  }
  localStorage.setItem('token', '123456')
  router.push({ path: '/' })
}

watch(() => [username.value, password.value], () => {
  usernameError.value = ''
  passwordError.value = ''
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "text-center mb-4 mt-5" }, [
      _createElementVNode("a", {
        href: ".",
        class: "navbar-brand navbar-brand-autodark"
      }, [
        _createElementVNode("img", {
          src: _imports_0,
          width: "110",
          height: "32",
          alt: "Tabler",
          class: "navbar-brand-image"
        })
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[7] || (_cache[7] = _createElementVNode("h2", { class: "h2 text-center mb-4" }, "Login to your account", -1)),
        _createElementVNode("form", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[3] || (_cache[3] = _createElementVNode("label", { class: "form-label" }, "NIK / Username", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: _normalizeClass(`form-control ${usernameError.value ? 'is-invalid' : ''}`),
              placeholder: "NIK / Username",
              autocomplete: "off",
              onKeyup: _withKeys(login, ["enter"]),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((username).value = $event)),
              required: ""
            }, null, 34), [
              [_vModelText, username.value]
            ]),
            (usernameError.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(usernameError.value), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[6] || (_cache[6] = _createElementVNode("label", { class: "form-label" }, "Password", -1)),
            _createElementVNode("div", _hoisted_8, [
              _withDirectives(_createElementVNode("input", {
                type: showPwd.value ? 'text' : 'password',
                class: "form-control",
                placeholder: "Your password",
                autocomplete: "off",
                onKeyup: _withKeys(login, ["enter"]),
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
                required: ""
              }, null, 40, _hoisted_9), [
                [_vModelDynamic, password.value]
              ]),
              _createElementVNode("span", {
                class: "input-group-text",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (showPwd.value = !showPwd.value))
              }, [
                _createElementVNode("a", _hoisted_10, [
                  (showPwd.value)
                    ? (_openBlock(), _createElementBlock("svg", _hoisted_11, _cache[4] || (_cache[4] = [
                        _createElementVNode("path", {
                          stroke: "none",
                          d: "M0 0h24v24H0z",
                          fill: "none"
                        }, null, -1),
                        _createElementVNode("path", { d: "M10.585 10.587a2 2 0 0 0 2.829 2.828" }, null, -1),
                        _createElementVNode("path", { d: "M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" }, null, -1),
                        _createElementVNode("path", { d: "M3 3l18 18" }, null, -1)
                      ])))
                    : (_openBlock(), _createElementBlock("svg", _hoisted_12, _cache[5] || (_cache[5] = [
                        _createElementVNode("path", {
                          stroke: "none",
                          d: "M0 0h24v24H0z",
                          fill: "none"
                        }, null, -1),
                        _createElementVNode("path", { d: "M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" }, null, -1),
                        _createElementVNode("path", { d: "M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" }, null, -1)
                      ])))
                ])
              ])
            ]),
            (passwordError.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(passwordError.value), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", { class: "form-footer" }, [
            _createElementVNode("button", {
              type: "button",
              onClick: login,
              class: "btn btn-primary w-100"
            }, "Sign in")
          ])
        ])
      ])
    ])
  ]))
}
}

})