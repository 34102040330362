<script setup lang="ts">

import Timer from "@/components/home/Timer.vue";
import {ref} from "vue";

const props = defineProps({
  show: Boolean,
})
const emit = defineEmits(['close', 'set-progress'])

const factors = ['Dies', 'Machine', 'Parameter Setting', 'Man Power', 'Metode']
const spvs = ['Budi', 'Santoso', 'Ahmad', 'Heru']

const problem = ref('')
const correctiveAction = ref('')
const factor = ref('Dies')
const analisa = ref([''])
const preventiveAction = ref([''])
const spv = ref('')

const addAnalisa = index => {
  let arr = analisa.value
  arr.splice(index + 1, 0, "")
  analisa.value = arr
}
const deleteAnalisa = index => {
  let arr = analisa.value
  if (arr.length === 1) {
    return
  }
  arr.splice(index, 1)
  analisa.value = arr
}

const addPreventiveAction = index => {
  let arr = preventiveAction.value
  arr.splice(index + 1, 0, "")
  preventiveAction.value = arr
}
const deletePreventiveAction = index => {
  let arr = preventiveAction.value
  if (arr.length === 1) {
    return
  }
  arr.splice(index, 1)
  preventiveAction.value = arr
}

const reset = () => {
  problem.value = ''
  correctiveAction.value = ''
  factor.value = 'Dies'
  analisa.value = ['']
  preventiveAction.value = ['']
  spv.value = ''
}

</script>

<template>
  <div class="modal modal-blur fade show" id="modal-large" tabindex="-1" :style="{display: show ? 'block' : 'none'}" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header p-2">
          <div style="flex: 1">
            <Timer/>
          </div>
          <div style="flex: 1; text-align: center">
            <h1 class="modal-title mb-0" style="font-size: 1.5rem">Action Plan</h1>
            <p>Foreman: Heru Budi</p>
          </div>
          <div style="flex: 1; text-align: end">
            <button type="button" @click="emit('close')" class="btn-close" aria-label="Close"></button>
          </div>
        </div>
        <div class="modal-body p-0">
          <table class="table card-table">
            <thead>
            <tr>
              <th>Problem</th>
              <th>Corrective Action</th>
              <th>Factor</th>
              <th>Analisa</th>
              <th>Preventive Action</th>
              <th>SPV</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <textarea class="form-control form-control-sm" v-model="problem" rows="5"></textarea>
              </td>
              <td>
                <textarea class="form-control form-control-sm" v-model="correctiveAction" rows="5"></textarea>
              </td>
              <td>
                <select class="form-control form-control-sm" v-model="factor">
                  <option v-for="factor in factors">{{ factor }}</option>
                </select>
              </td>
              <td>
                <div v-for="(_, k) in analisa" class="d-flex mb-1">
                  <input type="text" class="form-control form-control-sm" v-model="analisa[k]">
                  <div style="width: 6px"></div>
                  <svg @click="addAnalisa(k)" style="cursor:pointer;" xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="#30B344"  class="icon icon-tabler icons-tabler-filled icon-tabler-circle-plus"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4.929 4.929a10 10 0 1 1 14.141 14.141a10 10 0 0 1 -14.14 -14.14zm8.071 4.071a1 1 0 1 0 -2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0 -2h-2v-2z" /></svg>
                  <div style="width: 4px"></div>
                  <svg @click="deleteAnalisa(k)" style="cursor:pointer;" xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="#D63938"  class="icon icon-tabler icons-tabler-filled icon-tabler-circle-x"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" /></svg>
                </div>
              </td>
              <td>
                <div v-for="(_, k) in preventiveAction" class="d-flex mb-1">
                  <input type="text" class="form-control form-control-sm" v-model="preventiveAction[k]">
                  <div style="width: 6px"></div>
                  <svg @click="addPreventiveAction(k)" style="cursor:pointer;" xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="#30B344"  class="icon icon-tabler icons-tabler-filled icon-tabler-circle-plus"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4.929 4.929a10 10 0 1 1 14.141 14.141a10 10 0 0 1 -14.14 -14.14zm8.071 4.071a1 1 0 1 0 -2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0 -2h-2v-2z" /></svg>
                  <div style="width: 4px"></div>
                  <svg @click="deletePreventiveAction(k)" style="cursor:pointer;" xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="#D63938"  class="icon icon-tabler icons-tabler-filled icon-tabler-circle-x"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" /></svg>
                </div>
              </td>
              <td>
                <select class="form-control form-control-sm" v-model="spv">
                  <option v-for="v in spvs">{{ v }}</option>
                </select>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" @click="emit('close')" class="btn btn-primary">Cancel</button>
          <button type="button" @click="emit('close')" class="btn btn-success">Submit</button>
          <button type="button" @click="reset" class="btn">Reset</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>