<script setup lang="ts">

import { ref, onMounted, onBeforeUnmount } from 'vue';

const currentDate = new Date().toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' });
const currentTime = ref(formatTime(new Date()));
const currentShift = ref(2);

function formatTime(date) {
  return date.toLocaleTimeString('en-GB', { hour12: false });
}

function updateTime() {
  currentTime.value = formatTime(new Date());
}

let timer;
onMounted(() => {
  timer = setInterval(updateTime, 1000);
});

onBeforeUnmount(() => {
  clearInterval(timer);
});

</script>

<template>
  <div class="d-flex flex-column">
    <span><span class="margin">Date</span> {{currentDate}}</span>
    <span><span class="margin">Time</span> {{currentTime}}</span>
    <span><span class="margin">Shift</span> {{currentShift}}</span>
  </div>
</template>

<style scoped>
.margin {
  display: inline-block;
  width: 50px;
}
</style>