import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "page"
}
const _hoisted_2 = { class: "page-wrapper" }
const _hoisted_3 = {
  key: 1,
  class: "page page-center"
}

import { useRoute } from 'vue-router'
import Navbar from "@/components/partials/Navbar.vue";
import "@/assets/js/demo.js";
import "@/assets/js/tabler.js";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const route = useRoute()

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_unref(route).name !== 'login')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(Navbar),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_router_view)
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createVNode(_component_router_view)
      ]))
}
}

})