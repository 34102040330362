<script setup lang="ts">

import Timer from "@/components/home/Timer.vue";
import {ref} from "vue";
import ModalActionPlan from "@/components/home/ModalActionPlan.vue";

const data = [
  {
    "machine": "5-8",
    "eff_shift": "61%",
    "pro_name": "PRO Name",
    "part_name": "Part Name",
    "efficiency": "32%",
    "action": 1,
    "spv": 'Saiful',
    "status": 'Open'
  },
  {
    "machine": "7-1",
    "eff_shift": "66%",
    "pro_name": "PRO Name",
    "part_name": "Part Name",
    "efficiency": "47%",
    "action": 1,
    "spv": 'Saiful',
    "status": 'On Progress'
  },
  {
    "machine": "2-5",
    "eff_shift": "55%",
    "pro_name": "PRO Name",
    "part_name": "Part Name",
    "efficiency": "37%",
    "action": 1,
    "spv": 'Saiful',
    "status": 'On Progress'
  },
  {
    "machine": "9-7",
    "eff_shift": "41%",
    "pro_name": "PRO Name",
    "part_name": "Part Name",
    "efficiency": "67%",
    "action": 1,
    "spv": 'Saiful',
    "status": 'Close'
  },
  {
    "machine": "4-6",
    "eff_shift": "45%",
    "pro_name": "PRO Name",
    "part_name": "Part Name",
    "efficiency": "32%",
    "action": 1,
    "spv": 'Saiful',
    "status": 'Open'
  },
  {
    "machine": "6-9",
    "eff_shift": "48%",
    "pro_name": "PRO Name",
    "part_name": "Part Name",
    "efficiency": "44%",
    "action": null,
    "spv": 'Saiful',
    "status": 'Open'
  },
  {
    "machine": "3-4",
    "eff_shift": "64%",
    "pro_name": "PRO Name",
    "part_name": "Part Name",
    "efficiency": "69%",
    "action": null,
    "spv": 'Saiful',
    "status": 'Open'
  },
  {
    "machine": "8-2",
    "eff_shift": "38%",
    "pro_name": "PRO Name",
    "part_name": "Part Name",
    "efficiency": "50%",
    "action": null,
    "spv": 'Saiful',
    "status": 'Open'
  },
  {
    "machine": "1-3",
    "eff_shift": "69%",
    "pro_name": "PRO Name",
    "part_name": "Part Name",
    "efficiency": "59%",
    "action": null,
    "spv": 'Saiful',
    "status": 'Open'
  }
]
const showModal = ref(false)

</script>

<template>
  <div class="page-header d-print-none">
    <div class="container-xl">
      <div class="row g-2 align-items-center">
        <div class="col">
          <div class="page-pretitle">
            Overview
          </div>
          <h2 class="page-title">
            Dashboard
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div class="page-body">
    <div class="container-xl">
      <div class="row row-deck row-cards">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <div style="flex: 1">
                <Timer/>
              </div>
              <div style="flex: 1" class="text-center">
                <h1 class="mb-1">Shift Efficiency</h1>
                <p>Foreman: Bambang</p>
              </div>
              <div style="flex: 1">
                <div class="d-flex flex-column ps-5">
                  <div class="row">
                    <div class="col-sm-6 d-flex align-items-center mb-1">
                      <div class="legend legend-open"></div> Open
                    </div>
                    <div class="col-sm-6 d-flex align-items-center mb-1">
                      <div class="legend legend-close"></div> Close
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 d-flex align-items-center mb-1">
                      <div class="legend legend-waiting"></div> Waiting
                    </div>
                    <div class="col-sm-6 d-flex align-items-center mb-1">
                      <div class="legend legend-rejected"></div> Rejected
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 d-flex align-items-center mb-1" style="white-space: nowrap; overflow: hidden;">
                      <div class="legend legend-on-progress"></div> On Progress
                    </div>
                    <div class="col-sm-6 d-flex align-items-center mb-1">
                      <div class="legend legend-deleted"></div> Deleted
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <table class="table card-table table-vcenter">
              <thead>
              <tr>
                <th>No</th>
                <th>Machine</th>
                <th>Eff Shift</th>
                <th>PRO Name</th>
                <th>Part Name</th>
                <th>Efficiency</th>
                <th>Action</th>
                <th>SPV</th>
                <th>Status</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(v, k) in data.sort((a, b) => +a.eff_shift.localeCompare(b.eff_shift))" :key="v.id">
                <td>{{k + 1}}</td>
                <td>{{v.machine}}</td>
                <td>{{v.eff_shift}}</td>
                <td>{{v.pro_name}}</td>
                <td>{{v.part_name}}</td>
                <td>{{v.efficiency}}</td>
                <td>
                  <div v-if="k < 5">
                    <button class="btn btn-primary btn-sm me-2">
                      Detail Downtime
                    </button>
                    <button class="btn btn-success btn-sm" @click="showModal = true">
                      Create AP
                    </button>
                  </div>
                </td>
                <td>{{k < 5 ? v.spv : ''}}</td>
                <td>
                  <div v-if="k < 5" :class="`status legend-${v.status?.toLowerCase().replaceAll(' ', '-')}`">
                    {{v.status}}
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalActionPlan :show="showModal" @close="showModal = false"/>
</template>

<style scoped>
.legend {
  width: 40px;
  height: 16px;
  border-radius: 4px;
  margin-right: 8px;
}
.legend-open {
  background: #587835;
}
.legend-waiting {
  background: #D3BBA2;
}
.legend-on-progress {
  background: #A39BB0;
}
.legend-close {
  background: #5DC9E4;
}
.legend-rejected {
  background: #E6EA89;
}
.legend-deleted {
  background: #EF458A;
}
.status {
  color: white;
  white-space: nowrap;
  overflow: hidden;
  font-size: .7rem;
  font-weight: bold;
}
</style>