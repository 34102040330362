<script setup lang="ts">
import { useRoute } from 'vue-router'
import Navbar from "@/components/partials/Navbar.vue";
import "@/assets/js/demo.js";
import "@/assets/js/tabler.js";

const route = useRoute()
</script>

<template>
  <div v-if="route.name !== 'login'" class="page">
    <Navbar/>
    <div class="page-wrapper">
      <router-view></router-view>
    </div>
  </div>
  <div v-else class="page page-center">
    <router-view></router-view>
  </div>
</template>

<style>
  @import url('https://rsms.me/inter/inter.css');
  :root {
    --tblr-font-sans-serif: 'Inter Var', -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;
  }
  body {
    font-family: 'Inter Var', -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;
    font-feature-settings: "cv03", "cv04", "cv11";
  }
  @media (min-width: 768px) {
    .modal-xl {
      width: 95% !important;
      max-width:1300px !important;
    }
  }
  @import "@/assets/styles/demo.css";
  @import "@/assets/styles/tabler.css";
  @import "@/assets/styles/tabler-vendors.css";
</style>