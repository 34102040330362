<script setup lang="ts">

import {ref, watch} from "vue";
import router from "@/router";

const username = ref("")
const password = ref("")
const usernameError = ref("")
const passwordError = ref("")
const showPwd = ref(false)

const login = () => {
  if (username.value.length < 2) {
    usernameError.value = 'NIK / Username tidak ditemukan'
    return
  }
  if (password.value != '111111') {
    passwordError.value = 'Password salah'
    return
  }
  localStorage.setItem('token', '123456')
  router.push({ path: '/' })
}

watch(() => [username.value, password.value], () => {
  usernameError.value = ''
  passwordError.value = ''
})

</script>

<template>
  <div class="container container-tight py-4">
    <div class="text-center mb-4 mt-5">
      <a href="." class="navbar-brand navbar-brand-autodark">
        <img src="@/assets/logo.png" width="110" height="32" alt="Tabler" class="navbar-brand-image">
      </a>
    </div>
    <div class="card card-md">
      <div class="card-body">
        <h2 class="h2 text-center mb-4">Login to your account</h2>
        <form action="./" method="get" autocomplete="off" novalidate>
          <div class="mb-3">
            <label class="form-label">NIK / Username</label>
            <input type="text" :class="`form-control ${usernameError ? 'is-invalid' : ''}`" placeholder="NIK / Username" autocomplete="off" v-on:keyup.enter="login" v-model="username" required>
            <div v-if="usernameError" class="invalid-feedback">
              {{ usernameError }}
            </div>
          </div>
          <div class="mb-2">
            <label class="form-label">Password</label>
            <div class="input-group input-group-flat">
              <input :type="showPwd ? 'text' : 'password'" class="form-control" placeholder="Your password" autocomplete="off" v-on:keyup.enter="login" v-model="password" required>
              <span class="input-group-text" @click="showPwd = !showPwd">
                  <a href="javascript:;" class="link-secondary" title="Show password" data-bs-toggle="tooltip"><!-- Download SVG icon from http://tabler-icons.io/i/eye -->
                    <svg v-if="showPwd" xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-eye-off"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" /><path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" /><path d="M3 3l18 18" /></svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                         stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                         stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path
                      d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"/><path
                      d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"/></svg>
                  </a>
                </span>
            </div>
            <div v-if="passwordError" class="invalid-feedback">
              {{ passwordError }}
            </div>
          </div>
          <div class="form-footer">
            <button type="button" @click="login" class="btn btn-primary w-100">Sign in</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>