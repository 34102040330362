import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column" }

import { ref, onMounted, onBeforeUnmount } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Timer',
  setup(__props) {


const currentDate = new Date().toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' });
const currentTime = ref(formatTime(new Date()));
const currentShift = ref(2);

function formatTime(date) {
  return date.toLocaleTimeString('en-GB', { hour12: false });
}

function updateTime() {
  currentTime.value = formatTime(new Date());
}

let timer;
onMounted(() => {
  timer = setInterval(updateTime, 1000);
});

onBeforeUnmount(() => {
  clearInterval(timer);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", null, [
      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "margin" }, "Date", -1)),
      _createTextVNode(" " + _toDisplayString(_unref(currentDate)), 1)
    ]),
    _createElementVNode("span", null, [
      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "margin" }, "Time", -1)),
      _createTextVNode(" " + _toDisplayString(currentTime.value), 1)
    ]),
    _createElementVNode("span", null, [
      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "margin" }, "Shift", -1)),
      _createTextVNode(" " + _toDisplayString(currentShift.value), 1)
    ])
  ]))
}
}

})